import React from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => (
    <nav>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/saints">Saints</Link></li>
            <li><Link to="/cosmos">Cosmos</Link></li>
            <li><Link to="/skins">Skins</Link></li>
            <li>More?…</li>
        </ul>
    </nav>
)

export default NavBar