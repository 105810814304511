import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import "./assets/css/app.css";

import NavBar from "./components/navbar/NavBar";
import CosmosList from "./components/cosmos/CosmosList";
import SkinsList from "./components/skins/SkinsList";
import NotFound from "./components/not-found/NotFound";
import SaintsList from "./components/saints/SaintsList";
import HomePage from "./components/homepage/HomePage";


function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <NavBar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={ <HomePage /> } />
            <Route path="/saints" element={ <SaintsList /> } />
            <Route path="/cosmos" element={ <CosmosList /> } />
            <Route path="/skins" element={ <SkinsList /> } />
            {/* Error route */}
            <Route path="*" element={ <NotFound /> } />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
